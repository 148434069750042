import React, { useState } from 'react';
import "./login.css";
import { useNavigate } from "react-router-dom";
function Login() {
  let navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const loginFunction = () => {
    if (userName === "admin" && password === "123456") {
      let path = `/dashboard`;
      navigate(path);
    } else {
      alert("Incorrect Login Details")
    }
  }
  return (
    <div className='body'>
      <div className="login-page">
        <div className="form">
          <h2>ADMIN - LOGIN</h2>
          <input type="text" required placeholder="Username" autocomplete="off" onChange={(e) => setUserName(e.target.value)} />
          <input type="password" required placeholder="Password" autocomplete="off" onChange={(e) => setPassword(e.target.value)} />
          <button type="submit" onClick={() => loginFunction()}>LOGIN</button>
        </div>
      </div>
    </div>
  )
}

export default Login;